import classNames from 'classnames';
import classes from 'components/generics/fields/CodeInput/index.module.scss';
import React, { HTMLAttributes } from 'react';
import ReactCodeInput from 'react-code-input';

const DEFAULT_LENGTH = 6;
const DEFAULT_INVALID_STYLE = {
  border: '1px solid var(--color-rust)',
  color: 'var(--color-iron)',
};

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  length: number;
  onChange?: (code: number | string) => void;
  isValid?: boolean;
}

const CodeInput = ({
  length = DEFAULT_LENGTH,
  isValid,
  onChange,
  className,
}: Props) => {
  return (
    <>
      <ReactCodeInput
        fields={length}
        className={classNames(classes.inputs, className)}
        name="codeInput"
        isValid={isValid}
        onChange={onChange}
        inputMode="numeric"
        type="number"
        inputStyleInvalid={DEFAULT_INVALID_STYLE}
      />
      {!isValid && (
        <label className={classes.label}>Invalid code, please try again</label>
      )}
    </>
  );
};

export default CodeInput;
