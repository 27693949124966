import classNames from 'classnames';
import classes from 'components/animations/SignUpAnimation/index.module.scss';
import Typography from 'components/generics/texts/Typography';
import Head from 'components/next/Head';
import Image from 'next/image';
import React, { HTMLAttributes } from 'react';

const SignUpAnimation = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames(classes.container, className)}>
      <Head title="Sign Up" />
      <div className={classes.topContainer}>
        <Typography className={classes.title}>
          Start your real estate investing journey today
        </Typography>
      </div>
      <div className={classes.imagesContainer}>
        <Image
          className={classNames(classes.image, classes.city)}
          src={'/assets/welcome/welcomeBg.png'}
          alt={'welcomeBg'}
          width={1000}
          height={1000}
        />
        <Image
          className={classNames(classes.image, classes.bushes)}
          src={'/assets/welcome/welcomeFg.png'}
          alt={'welcomeFg'}
          width={1000}
          height={1000}
        />
      </div>
    </div>
  );
};

export default SignUpAnimation;
