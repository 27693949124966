import classNames from 'classnames';
import classes from 'components/containers/SplitScreenComponent/index.module.scss';
import { motion } from 'framer-motion';
import React, { HTMLAttributes, ReactElement } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  LeftComponent: ReactElement;
  RightComponent: ReactElement;
  leftContainerClassName?: string;
  rightContainerClassName?: string;
  leftContainerStyle?: React.CSSProperties;
  leftContentClassName?: string;
  rightContentClassName?: string;
}

const SplitScreenComponent = ({
  LeftComponent,
  RightComponent,
  rightContainerClassName,
  rightContentClassName,
  leftContentClassName,
  leftContainerClassName,
  leftContainerStyle,
  className,
}: Props) => {
  return (
    <div className={classNames(classes.container, className)}>
      <motion.div
        layout="position"
        className={classNames(classes.leftContainer, leftContainerClassName)}
        style={leftContainerStyle}
      >
        <div className={leftContentClassName}>{LeftComponent}</div>
      </motion.div>
      <motion.div
        layout="position"
        className={classNames(classes.rightContainer, rightContainerClassName)}
      >
        <div className={rightContentClassName}>{RightComponent}</div>
      </motion.div>
    </div>
  );
};

export default SplitScreenComponent;
