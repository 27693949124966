import { gql } from '@apollo/client';

export const VERIFY_TWO_FACTOR_AUTH = gql`
  mutation ($identifier: String!, $otp: String, $magicLinkId: String) {
    verify2FA(
      auth_method_identifier: $identifier
      otp: $otp
      magic_link_id: $magicLinkId
    ) {
      success
      data {
        token
        account_number
      }
    }
  }
`;
