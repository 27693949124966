import PageDotsLoader from 'components/animations/Loaders/PageDotsLoader';
import CodeInput from 'components/generics/fields/CodeInput';
import classes from 'components/generics/flows/LoginSignUpFlow/SharedComponents/LoginSignupCodeVerify/index.module.scss';
import useLoginSignupCodeVerify from 'components/generics/flows/LoginSignUpFlow/SharedComponents/LoginSignupCodeVerify/useLoginSignupCodeVerify';
import Typography from 'components/generics/texts/Typography';
import React, { HTMLAttributes } from 'react';

export const LOGIN_VERIFY_CODE_LENGTH = 6;

interface Props extends HTMLAttributes<HTMLDivElement> {
  isEmail: boolean;
  identifier: string;
  onSuccess: () => void;
  tenant: boolean;
}

const LoginSignupCodeVerify = ({
  isEmail,
  identifier,
  onSuccess,
  tenant,
}: Props) => {
  const { isValid, loading, handleCodeChange } = useLoginSignupCodeVerify(
    identifier,
    onSuccess,
    tenant
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        Check your {isEmail ? 'inbox' : 'mobile device'}
      </Typography>
      <Typography className={classes.subtitle}>
        Your one-time verification code was sent to {identifier}
      </Typography>
      <CodeInput
        length={LOGIN_VERIFY_CODE_LENGTH}
        onChange={handleCodeChange}
        isValid={isValid}
      />
      <div className={classes.loader}>
        <PageDotsLoader
          isLoading={loading}
          withClass={false}
        />
      </div>
    </div>
  );
};

export default LoginSignupCodeVerify;
