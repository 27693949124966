import { gql } from '@apollo/client';

export const CHECK_USER_EXISTS = gql`
  mutation CheckUserExists($mobile: String, $email: String) {
    checkUserExists(mobile: $mobile, email: $email) {
      data {
        exists
      }
    }
  }
`;
