import { FlowComponentProps } from 'components/generics/flows/Flow';
import classes from 'components/generics/flows/LoginSignUpFlow/LoginFlow/components/LoginVerify/index.module.scss';
import { useLogin } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context';
import { LOGIN_METHODS } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context/utils';
import LoginSignupCodeVerify from 'components/generics/flows/LoginSignUpFlow/SharedComponents/LoginSignupCodeVerify';
import { LOGIN_PROCESS_COMPLETE } from 'consts/analytics/onBoardingEvents';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import BackButtonIcon from 'public/assets/icons/chevron_left.svg';
import React from 'react';
import useUserDetails from '../../../../../../../hooks/requests/user/hooks/useUserDetails';

const LoginVerify = ({
  goBack,
  onFinish,
  tenant,
}: Partial<FlowComponentProps>) => {
  const { email, phoneNumber, selectedMethod } = useLogin();
  const isEmail = selectedMethod === LOGIN_METHODS.EMAIL;
  const identifier = isEmail ? email : phoneNumber;
  const { userDetails, initRequest } = useUserDetails();

  const onLogin = async () => {
    if (initRequest) {
      await initRequest;
      analytics.track(LOGIN_PROCESS_COMPLETE, userDetails);
    }
    onFinish?.();
  };

  return (
    <div className={classes.container}>
      {goBack && (
        <BackButtonIcon
          onClick={() => goBack()}
          className={classes.backButton}
        />
      )}
      <LoginSignupCodeVerify
        isEmail={selectedMethod === LOGIN_METHODS.EMAIL}
        identifier={identifier}
        onSuccess={onLogin}
        tenant={tenant ? tenant : false}
      />
    </div>
  );
};

export default LoginVerify;
