import ChangeEmailPhoneButton from 'components/generics/flows/LoginSignUpFlow/LoginFlow/components/Login/components/LoginForm/components/ChangeEmailPhoneButton';
import classes from 'components/generics/flows/LoginSignUpFlow/LoginFlow/components/Login/components/LoginForm/index.module.scss';
import { useLogin } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context';
import { LOGIN_METHODS } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context/utils';
import EmailInput from 'components/generics/inputs/EmailInput';
import PhoneInput from 'components/generics/inputs/PhoneInput';
import React, { HTMLAttributes } from 'react';

const LoginForm = ({ ...props }: HTMLAttributes<HTMLDivElement>) => {
  const {
    email,
    emailErrorMessage,
    phoneNumber,
    phoneNumberErrorMessage,
    methodChangeHandler,
    selectedMethod,
  } = useLogin();

  return (
    <div {...props}>
      <div className={classes.inputTitleContainer}>
        <ChangeEmailPhoneButton />
      </div>
      {selectedMethod === LOGIN_METHODS.PHONE_NUMBER ? (
        <PhoneInput
          value={phoneNumber}
          onChange={methodChangeHandler(LOGIN_METHODS.PHONE_NUMBER)}
        />
      ) : (
        <EmailInput
          name={'Email'}
          className={classes.inputContainer}
          inputClassName={classes.baseInput}
          value={email}
          onChange={methodChangeHandler(LOGIN_METHODS.EMAIL)}
          errorMessage={emailErrorMessage}
          withPlaceHolderTransition={true}
        />
      )}
    </div>
  );
};

export default LoginForm;
