import Button from 'components/generics/buttons/Button';
import classes from 'components/generics/flows/LoginSignUpFlow/SharedComponents/LoginSignupConfirmButton/index.module.scss';
import ArrowRight from 'public/assets/icons/arrow_right.svg';
import React from 'react';

interface Props {
  onClick: () => void;
  text: string;
  disabled: boolean;
  loading: boolean;
  hasError: boolean;
  errorMessage: string;
}

const LoginConfirm = ({
  onClick,
  text,
  disabled,
  loading,
  hasError,
  errorMessage,
}: Props) => {
  return (
    <div className={classes.container}>
      <Button
        loading={loading}
        className={classes.createButton}
        disabled={disabled}
        onClick={onClick}
        type={'submit'}
      >
        {text}
        <ArrowRight />
      </Button>
      {hasError && <div className={classes.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

export default LoginConfirm;
