import {
  isDateValid,
  isEmailValid,
  isFullNameValid,
  isPhoneValid,
} from 'utils/validators';

export enum SIGN_UP_FIELDS {
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  FULL_NAME = 'fullName',
  DATE_OF_BIRTH = 'dateOfBirth',
}

export const FIELD_VALIDATORS = {
  [SIGN_UP_FIELDS.EMAIL]: isEmailValid,
  [SIGN_UP_FIELDS.PHONE_NUMBER]: isPhoneValid,
  [SIGN_UP_FIELDS.FULL_NAME]: isFullNameValid,
  [SIGN_UP_FIELDS.DATE_OF_BIRTH]: (value: string) =>
    isDateValid(value, { validateOver18: true }),
};
