import Flow from 'components/generics/flows/Flow';
import Login from 'components/generics/flows/LoginSignUpFlow/LoginFlow/components/Login';
import LoginVerify from 'components/generics/flows/LoginSignUpFlow/LoginFlow/components/LoginVerify';
import { LoginProvider } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context';
import LandaTheme from 'components/layouts/LandaTheme';
import {
  FEED_ROUTE,
  RESIDENTS_RENT_PAY_ROUTE,
  SIGNUP_ROUTE,
} from 'consts/routes/urls';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import React from 'react';
import { THEMEABLE_COMPONENTS } from 'stores/LandaTheme/landaTheme';
import useUserDetailsStore from '../../../../../stores/UserDetails/userDetails';

type Props = {
  onSignUp?: () => void;
  onFinish?: () => void;
};

const LoginFlow = ({ onSignUp, onFinish }: Props) => {
  const components = [{ component: Login }, { component: LoginVerify }];
  const { navigate } = useLandaRouter();
  const { userDetails } = useUserDetailsStore();

  const handleFinish = () => {
    onFinish?.() ??
      navigate(userDetails?.isTenant ? RESIDENTS_RENT_PAY_ROUTE : FEED_ROUTE);
  };

  const handleSignUp = () => {
    onSignUp?.() ?? navigate(SIGNUP_ROUTE);
  };

  return (
    <LandaTheme id={THEMEABLE_COMPONENTS.LOGIN_FLOW}>
      <LoginProvider>
        <Flow
          components={components}
          onFinish={handleFinish}
          initialProps={{
            onSignUp: handleSignUp,
          }}
        />
      </LoginProvider>
    </LandaTheme>
  );
};

export default LoginFlow;
