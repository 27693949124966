import { gql } from '@apollo/client';

export const SEND_TWO_FACTOR_AUTH = gql`
  mutation (
    $authMethodType: String!
    $identifier: String!
    $callbackUrl: String
  ) {
    send2FA(
      auth_method_type: $authMethodType
      identifier: $identifier
      callback_url: $callbackUrl
    ) {
      success
    }
  }
`;
