import { useLogin } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context';
import { LOGIN_METHODS } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context/utils';
import { MAGIC_LINK_CALLBACK_URL } from 'consts';
import {
  CODE_VERIFY_ERROR,
  LOGIN_FAILED,
  SIGNUP_EMAIL_SENT,
  SIGNUP_SMS_SENT,
} from 'consts/analytics/onBoardingEvents';
import { SEND_TWO_FACTOR_AUTH } from 'graphql/mutations/sendTwoFactorAuth';
import { CHECK_USER_EXISTS } from 'graphql/user/mutations/useExist';
import { useAuthMutation } from 'hooks/api/graphql/auth';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { useEffect, useState } from 'react';
import { getRawNumber } from 'utils/formatters';

const useLoginConfirm = (onFinish: () => void) => {
  const [loading, setLoading] = useState(false);
  const [detailsNotExist, setDetailsNotExist] = useState(false);
  const [sendTwoFactorAuth] = useAuthMutation(SEND_TWO_FACTOR_AUTH);
  const { selectedMethod, email, phoneNumber, isFormValid } = useLogin();
  const isEmail = selectedMethod === LOGIN_METHODS.EMAIL;
  const [checkUserExists] = useAuthMutation(CHECK_USER_EXISTS);

  useEffect(() => {
    if (!isFormValid) setDetailsNotExist(false);
  }, [isFormValid]);

  const sendCode = async () => {
    const options = {
      variables: {
        authMethodType: isEmail ? 'email' : 'sms',
        identifier: isEmail ? email : getRawNumber(phoneNumber),
        callbackUrl: email ? MAGIC_LINK_CALLBACK_URL : undefined,
      },
    };
    try {
      const response = await sendTwoFactorAuth(options);
      const { send2FA } = response.data;
      if (!send2FA.success) {
        analytics.track(CODE_VERIFY_ERROR, options);
      } else {
        analytics.track(isEmail ? SIGNUP_EMAIL_SENT : SIGNUP_SMS_SENT);
      }
    } catch (e) {
      analytics.track(LOGIN_FAILED, options);
    }
  };

  const onConfirm = async () => {
    setLoading(true);
    const { data } = await checkUserExists({
      variables: isEmail ? { email } : { mobile: phoneNumber },
    });
    if (data?.checkUserExists?.data?.exists) {
      await sendCode();
      onFinish?.();
    } else {
      setDetailsNotExist(true);
    }
    setLoading(false);
  };

  return {
    onConfirm,
    loading,
    detailsNotExist,
  };
};

export default useLoginConfirm;
