import classNames from 'classnames';
import Transition from 'components/transitions/Transition';
import { CSSProperties } from 'react';
import { SyncLoader } from 'react-spinners';
import classes from './index.module.scss';

interface Props {
  isLoading?: boolean;
  color?: string;
  cssOverride?: CSSProperties;
  speedMultiplier?: 1;
  withClass?: boolean;
}

const PageDotsLoader = ({
  color = 'var(--color-concrete)',
  isLoading,
  speedMultiplier,
  cssOverride,
  withClass = true,
}: Props) => {
  if (!isLoading) return null;

  return (
    <div className={classNames(withClass && classes.loader)}>
      <Transition>
        <SyncLoader
          color={color}
          speedMultiplier={speedMultiplier}
          cssOverride={cssOverride}
        />
      </Transition>
    </div>
  );
};

export default PageDotsLoader;
