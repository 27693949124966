import classes from 'components/generics/flows/LoginSignUpFlow/SharedComponents/LoginSignupHeader/index.module.scss';
import Typography from 'components/generics/texts/Typography';
import React, { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitleQuestion: string;
  subtitleAnswer: string;
  onSwitchMethod: () => void;
}

const LoginSignUpHeader = ({
  title,
  subtitleQuestion,
  subtitleAnswer,
  onSwitchMethod,
  ...props
}: Props) => {
  return (
    <div {...props}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>
        {subtitleQuestion}{' '}
        <span
          className={classes.link}
          onClick={onSwitchMethod}
        >
          {subtitleAnswer}
        </span>
      </Typography>
    </div>
  );
};

export default LoginSignUpHeader;
