import { useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { AUTH_API, AUTH_API_TENANT } from 'graphql/config';
import { DocumentNode } from 'graphql/language';
import useApolloClient from 'hooks/api/graphql/clients/useApolloClient';
import useLandaLazyQuery, {
  OptionsType,
} from 'hooks/api/graphql/functions/useLandaLazyQuery';
import useLandaQuery from 'hooks/api/graphql/functions/useLandaQuery';

export const useAuthQuery = (query: DocumentNode, options?: OptionsType) => {
  const apolloClient = useApolloClient(AUTH_API);
  return useLandaQuery(query, { ...(options || {}), client: apolloClient });
};

export const useAuthLazyQuery = (
  query: DocumentNode,
  options?: OptionsType
) => {
  const apolloClient = useApolloClient(AUTH_API);
  return useLandaLazyQuery(query, { ...(options || {}), client: apolloClient });
};

export const useAuthMutation = (
  mutation: DocumentNode,
  tenant?: boolean,
  options?: MutationHookOptions
) => {
  const apolloClient = useApolloClient(tenant ? AUTH_API_TENANT : AUTH_API);
  return useMutation(mutation, { ...(options || {}), client: apolloClient });
};
