import * as Sentry from '@sentry/nextjs';
import { LOGIN_VERIFY_CODE_LENGTH } from 'components/generics/flows/LoginSignUpFlow/SharedComponents/LoginSignupCodeVerify/index';
import { CODE_VERIFY_ERROR } from 'consts/analytics/onBoardingEvents';
import useAuth from 'context/Auth';
import { VerifyCodeEntry } from 'context/Auth/types';
import { VERIFY_TWO_FACTOR_AUTH } from 'graphql/mutations/verifyTwoFactorAuth';
import { useAuthMutation } from 'hooks/api/graphql/auth';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { useState } from 'react';

const useLoginSignupCodeVerify = (
  identifier: string,
  onSuccess: () => void,
  tenant?: boolean,
  useMagicLink?: boolean
) => {
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [verifyTwoFactorAuth] = useAuthMutation(VERIFY_TWO_FACTOR_AUTH, tenant);
  const { loginSignupSuccess } = useAuth();

  const validateCode = async (code: string) => {
    setLoading(true);
    const options: { variables: VerifyCodeEntry } = {
      variables: {
        identifier: identifier,
        ...(useMagicLink ? { magicLinkId: code } : { otp: code }),
      },
    };
    try {
      const response = await verifyTwoFactorAuth(options);
      const { verify2FA } = response.data;
      if (verify2FA?.data?.token) {
        await loginSignupSuccess(
          verify2FA?.data?.token,
          verify2FA?.data?.account_number
        );
        await onSuccess();
      }
    } catch (error) {
      analytics.track(CODE_VERIFY_ERROR, options);
      setIsValid(false);
      Sentry.captureException(error);
    }
    setLoading(false);
  };

  const handleCodeChange = async (newCode: string | number) => {
    const code = newCode.toString();
    if (code.length <= LOGIN_VERIFY_CODE_LENGTH && !useMagicLink) {
      setIsValid(true);
    }
    if (
      (code.length === LOGIN_VERIFY_CODE_LENGTH && !useMagicLink) ||
      useMagicLink
    ) {
      await validateCode(code);
    }
  };

  return {
    isValid,
    setIsValid,
    validateCode,
    loading,
    handleCodeChange,
  };
};

export default useLoginSignupCodeVerify;
