import classes from 'components/generics/flows/LoginSignUpFlow/LoginFlow/components/Login/components/LoginForm/components/ChangeEmailPhoneButton/index.module.scss';
import { useLogin } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context';
import { LOGIN_METHODS } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context/utils';
import Typography from 'components/generics/texts/Typography';
import Phone from 'public/assets/icons/device.svg';
import Email from 'public/assets/icons/email_fold.svg';
import React from 'react';

const ChangeEmailPhoneButton = () => {
  const { selectedMethod, onChangeMethod } = useLogin();
  const onClick = () =>
    onChangeMethod(
      selectedMethod === LOGIN_METHODS.EMAIL
        ? LOGIN_METHODS.PHONE_NUMBER
        : LOGIN_METHODS.EMAIL
    );

  return (
    <div
      className={classes.container}
      onClick={onClick}
    >
      {selectedMethod === LOGIN_METHODS.EMAIL ? (
        <>
          <Phone />
          <Typography>Use Phone Number</Typography>
        </>
      ) : (
        <>
          <Email />
          <Typography>Use Email</Typography>
        </>
      )}
    </div>
  );
};

export default ChangeEmailPhoneButton;
