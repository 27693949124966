import SignUpAnimation from 'components/animations/SignUpAnimation';
import SplitScreenComponent from 'components/containers/SplitScreenComponent';
import LoginFlow from 'components/generics/flows/LoginSignUpFlow/LoginFlow';
import PublicOnly from 'hocs/PublicOnly';
import React from 'react';
import classes from './index.module.scss';

const LoginPage = () => {
  return (
    <SplitScreenComponent
      LeftComponent={<SignUpAnimation />}
      RightComponent={<LoginFlow />}
      rightContainerClassName={classes.rightContainer}
      leftContainerClassName={classes.leftContainer}
      rightContentClassName={classes.rightContent}
    />
  );
};

export default PublicOnly(LoginPage);
