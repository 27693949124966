import classes from 'components/generics/flows/LoginSignUpFlow/index.module.scss';
import LoginForm from 'components/generics/flows/LoginSignUpFlow/LoginFlow/components/Login/components/LoginForm';
import useLoginConfirm from 'components/generics/flows/LoginSignUpFlow/LoginFlow/components/Login/useLoginConfirm';
import { useLogin } from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context';
import LoginSignupConfirmButton from 'components/generics/flows/LoginSignUpFlow/SharedComponents/LoginSignupConfirmButton';
import LoginSignUpHeader from 'components/generics/flows/LoginSignUpFlow/SharedComponents/LoginSignupHeader';
import { useOnKeyPress } from 'hooks/logics/useOnKeyPress';
import React from 'react';

const ERROR_MESSAGE =
  "We're sorry, but we couldn't find any records associated with the information you provided. Don't worry though, you can easily sign up and create a brand new account with us!";

interface Props {
  onSignUp: () => void;
  onFinish: () => void;
}

const Login = ({ onFinish, onSignUp }: Props) => {
  const { isFormValid } = useLogin();
  const { onConfirm, loading, detailsNotExist } = useLoginConfirm(onFinish);

  useOnKeyPress(() => {
    isFormValid && onConfirm();
  });

  return (
    <div className={classes.container}>
      <LoginSignUpHeader
        className={classes.header}
        title={'Log in'}
        subtitleQuestion={'Not a member yet?'}
        subtitleAnswer={'Create an Account'}
        onSwitchMethod={onSignUp}
      />
      <LoginForm className={classes.form} />
      <LoginSignupConfirmButton
        hasError={detailsNotExist}
        errorMessage={ERROR_MESSAGE}
        loading={loading}
        onClick={onConfirm}
        text={'Login'}
        disabled={!isFormValid}
      />
    </div>
  );
};

export default Login;
