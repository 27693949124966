import { FC } from 'react';
import Phone from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import classes from './index.module.scss';

const DEFAULT_PLACEHOLDER = '123-456-7890';

interface PhoneInputProps {
  noBorder?: boolean;
  errorMessage?: string;
  label?: string;
  value: string;
  placeholder?: string;
  name?: string;
  onChange: (value: string) => void;
  inputStyle?: any;
  containerStyle?: any;
}

const PhoneInput: FC<PhoneInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  inputStyle,
  containerStyle,
}) => {
  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <Phone
        value={value}
        buttonStyle={{
          height: 48,
          backgroundColor: 'transparent',
          border: 0,
        }}
        inputStyle={{
          width: '100%',
          height: 48,
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          borderRadius: 0,
          borderBottomColor: '#f0efef',
          marginBottom: 16,
          fontWeight: 600,
          fontSize: 16,
          color: '#515151',
          ...inputStyle,
        }}
        containerStyle={{
          marginBottom: 24,
          ...containerStyle,
        }}
        country="us"
        placeholder={placeholder ?? DEFAULT_PLACEHOLDER}
        onChange={onChange}
        inputProps={{
          name,
        }}
      />
    </div>
  );
};

export default PhoneInput;
