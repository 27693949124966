import BaseInputError from 'components/generics/inputs/BaseInputError';
import { InputHTMLAttributes } from 'react';
import inputClasses from '../index.module.scss';
import { AnyKindOfDictionary } from 'lodash';

const DEFAULT_PLACEHOLDER = 'yourname@mail.com';

interface EmailInputProps extends InputHTMLAttributes<HTMLInputElement> {
  noBorder?: boolean;
  errorMessage?: string;
  placeholderClass?: string;
  inputClassName?: string;
  withPlaceHolderTransition?: boolean;
  label?: string;
  inputStyle?: any;
}

const EmailInput = ({
  label,
  errorMessage,
  noBorder,
  inputClassName,
  withPlaceHolderTransition,
  placeholderClass,
  inputStyle,
  ...props
}: EmailInputProps) => {
  return (
    <div>
      <label className={inputClasses.label}>{label}</label>
      <BaseInputError
        autoComplete={'email'}
        type={'email'}
        mask={''}
        maskChar={''}
        placeholderClass={placeholderClass}
        placeholder={DEFAULT_PLACEHOLDER}
        errorMessage={errorMessage}
        noBorder={noBorder}
        inputClassName={inputClassName}
        withPlaceHolderTransition={withPlaceHolderTransition}
        inputStyle
        {...props}
      />
    </div>
  );
};

export default EmailInput;
