import { SIGN_UP_FIELDS } from 'components/generics/flows/LoginSignUpFlow/SignUpFlow/components/SignUp/utils';
import { isEmailValid, isPhoneValid } from 'utils/validators';
import { ChangeEvent } from 'react';

export enum LOGIN_METHODS {
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
}

export const METHOD_VALIDATORS = {
  [SIGN_UP_FIELDS.EMAIL]: isEmailValid,
  [SIGN_UP_FIELDS.PHONE_NUMBER]: isPhoneValid,
};

export type LoginContextType = {
  phoneNumber: string;
  phoneNumberErrorMessage: string;
  email: string;
  emailErrorMessage: string;
  selectedMethod: LOGIN_METHODS;
  isFormValid: boolean;
  onChangeMethod: (method: LOGIN_METHODS) => void;
  methodChangeHandler: (
    method: LOGIN_METHODS
  ) => (e: ChangeEvent<HTMLInputElement> | string) => void;
};

export const INITIAL_LOGIN_DATA = {
  email: {
    value: '',
    isValid: false,
    errorMessage: '',
  },
  phoneNumber: {
    value: '',
    isValid: false,
    errorMessage: '',
  },
};
