import {
  INITIAL_LOGIN_DATA,
  LOGIN_METHODS,
  LoginContextType,
  METHOD_VALIDATORS,
} from 'components/generics/flows/LoginSignUpFlow/LoginFlow/context/utils';
import { isString } from 'lodash';
import {
  ChangeEvent,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

const LoginContext = createContext<LoginContextType>({} as LoginContextType);

type Props = {
  children: ReactNode;
};

export const LoginProvider = ({ children }: Props) => {
  const [selectedMethod, setSelectedMethod] = useState<LOGIN_METHODS>(
    LOGIN_METHODS.EMAIL
  );
  const [methods, setMethods] = useState(INITIAL_LOGIN_DATA);

  const methodChangeHandler =
    (method: LOGIN_METHODS) =>
    (event: ChangeEvent<HTMLInputElement> | string) => {
      const value = isString(event) ? event : event.target.value;
      const { isValid, errorMessage } = METHOD_VALIDATORS[method](value);
      setMethods({
        ...methods,
        [method]: {
          value,
          isValid,
          errorMessage,
        },
      });
    };

  const onChangeMethod = (method: LOGIN_METHODS) => {
    setSelectedMethod(method);
    setMethods(INITIAL_LOGIN_DATA);
  };

  const isFormValid = useMemo(() => {
    return methods[selectedMethod].isValid;
  }, [methods, selectedMethod]);

  return (
    <LoginContext.Provider
      value={{
        email: methods.email.value,
        emailErrorMessage: methods.email.errorMessage,
        phoneNumber: methods.phoneNumber.value,
        phoneNumberErrorMessage: methods.phoneNumber.errorMessage,
        methodChangeHandler,
        onChangeMethod,
        isFormValid,
        selectedMethod,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => {
  return useContext(LoginContext);
};
