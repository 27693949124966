import BeatLoader from 'components/animations/Loaders/BeatLoader';
import { FEED_ROUTE } from 'consts/routes/urls';
import useAuth from 'context/Auth';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import React, { useMemo } from 'react';
import classes from './index.module.scss';

const PublicOnly = (Component: React.ComponentType) => {
  const WrapperComponent = (props: any) => {
    const { token, initialized } = useAuth();

    const tokenUponArrival = useMemo(() => token, [initialized]);
    const { navigate } = useLandaRouter();

    const LoadingPage = (
      <div className={classes.loaderContainer}>
        <BeatLoader size={20} />
      </div>
    );

    if (!initialized) return LoadingPage;
    if (!tokenUponArrival) return <Component {...props} />;
    navigate(FEED_ROUTE);
  };
  return WrapperComponent;
};

export default PublicOnly;
